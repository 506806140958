
window.___gcfg = {
    lang: "en-GB",
    parsetags: "onload"
};

new WOW({ offset: 70 }).init();

$(document).ready(function () {

    

    if ($("#HomepageMarker").length === 0) {
        /*$(".header__weather-sun").css("display","block");*/
    
        
    }
    else {
        $(".catlinks a").addClass("wow fadeIn").attr({ "data-wow-duration": "1.5s", "data-wow-offset": "120" });
        /*$(".header__logo").addClass("wow zoomIn").css("display", "block");*/
        /*$(".top-wrapper__stars").addClass("wow zoomIn2").attr('data-wow-duration','0.5s');*/
        /*$(".header__weather-sun").addClass("wow fadeIn").css("display", "block");*/
        //$(".top-wrapper__sun").addClass("wow fadeIn");
        //$(".top-wrapper__sun.top-wrapper__sun--bottom").css('display','block').attr({'data-wow-offset':'500', 'data-wow-delay': '1.5s', 'data-wow-duration': '3s'});
        $(".left-block").addClass("wow bounceIn").attr('data-wow-offset','300');
    };


    // ADD SLIDEDOWN ANIMATION TO DROPDOWN //
    $('.toplinks').on('show.bs.dropdown', function (e) {
        $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
        window.scrollBy(0, 1);
        window.scrollBy(1, 0);
    });

    // ADD SLIDEUP ANIMATION TO DROPDOWN //
    $('.toplinks').on('hide.bs.dropdown', function (e) {
        $(this).find('.dropdown-menu').first().stop(true, true).slideUp();


    });

    $(".toplinks__dropdown-link").each(function (index, value) {
        $(this).attr("data-wow-delay", index / 10 + "s");
        $(this).attr("data-wow-offset", 0);
        $(this).addClass("wow fadeIn");
    });


    $("img.has-tooltip").tooltip({
        title: function () {
            return $(this).attr("alt");
        },
        placement: "auto"
    });

    $("a.has-tooltip").tooltip({
        placement: "auto"
    });

    $(".home-gallery__previous").click(function (e) {
        var img = $(".home-gallery__slide").first();
        img.animate({ width: 0 }, 1000, "linear", function () {
            img.remove();
            $(".home-gallery__inner").append(img);
            img.width(140);
        });
        e.preventDefault();
        return false;
    });

    $(".home-gallery__next").click(function (e) {
        var img = $(".home-gallery__slide").last();
        img.width(0);
        img.remove();
        $(".home-gallery__inner").prepend(img);
        img.animate({ width: 140 }, 1000, "linear");
        e.preventDefault();
        return false;
    });


  

    $(window).on("resize", function() {
        setTimeout(function() {
            $('.testimonials__container').sameHeight();
            /*$('.testimonials .item').sameHeight();*/
            $('.testimonials__link').sameHeight();
            $('.featured-categories .carousel').sameHeight();
        },300);
    }).resize();


});

